import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import './cclStats.scss';

const stats = [
  {
    id: 'partners',
    stat: '3K',
    about: 'Organizations partner with us annually'
  },
  {
    id: 'fortune',
    stat: '2/3',
    about: 'Of the Fortune 1000 have worked with us'
  },
  {
    id: 'languages',
    stat: '50',
    about: 'Languages available for content delivery'
  }
];

const animateValue = (id, endString, end) => {
  let range = end - 0;
  let current = 0;
  let increment = end > 0 ? 1 : -1;
  let stepTime = Math.abs(Math.floor(1000 / range));
  let obj = document.getElementById(id);

  let timer = setInterval(function () {
    current += increment;
    obj.innerHTML = current + endString;
    if (current === end) {
      clearInterval(timer);
    }
  }, stepTime);
};

let counted = false;

const onVisible = isVisible => {
  if (isVisible && !counted) {
    animateValue('partners', 'K', 3);
    animateValue('languages', '', 50);
    counted = true;
  }
};

const OrgStats = ({ isVisible }) => {
  return (
    <div className="ccl-stats">
      <div className="stats">
        <h2 className="title">
          No matter how much or how quickly the world changes &mdash; one constant and absolute fact remains: great leadership can turn challenge into opportunity. CCL has been helping organizations navigate change and drive impact through individual and collective transformation for more than 50 years.
        </h2>
        <VisibilitySensor onChange={onVisible}>
          <div className="stats-content subpage">
            {stats.map(stat => (
              <div className="ccl-stat" key={stat.id}>
                <h2 id={stat.id}>{stat.stat}</h2>
                <h4>{stat.about}</h4>
              </div>
            ))}
          </div>
        </VisibilitySensor>
      </div>
    </div>
  );
};

export default OrgStats;
