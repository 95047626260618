import React from 'react';
import '../styles/global.scss';
import Layout from '../components/layout';
import CCLLeadspace from '../components/cclLeadspace';
import OrgStats from './partials/orgStats';
import JourneyForm from '../components/journeyForm';
import CCLExplore from '../components/cclExplore';
import CCLButton from '../components/cclButton';
import LeadImage from '../images/orginizations-leadspace.jpg';
import CardSlider from '../components/cardSlider';
import SEO from '../components/seo';
import Story1 from '../images/stories/organization1.png';
import Story2 from '../images/stories/organization2.jpg';
import Story3 from '../images/stories/organization3.png';
import Story4 from '../images/stories/organization4.jpg';

const stories = [
  {
    image: Story1,
    title: 'Finding the Right Questions',
    cta: 'view success story',
    expanded: {
      title: 'Change the System. Change the Culture. Change the Conversation.',
      subtitle: 'A powerful CCL solution helped Notre Dame transform evaluations into conversations.',
      contentTitle: '',
      link: 'https://www.ccl.org/articles/client-successes/giving-better-feedback-improving-conversations-to-scale-cultural-change/',
      content:
        '<p>Research has shown that employees who receive regular, constructive feedback are more engaged at work. Unfortunately, the University of Notre Dame’s employees were missing out on this critical opportunity. HR tried out multiple performance management processes and software solutions, but eventually realized something very important &mdash; if you don’t fix the core issue, any system will prove ineffective.</p><p>With CCL’s help, Notre Dame realized that employee performance and development is a function of organizational culture, not just human resources. Through CCL’s Better Conversations Every Day<sup>TM</sup> solution, Notre Dame’s leaders learned how to have constructive conversations with employees. And by training facilitators from HR and other departments, CCL helped ensure this new approach became a scalable, sustainable program embedded throughout the unversity’s culture.</p>'
    }
  },
  {
    image: Story2,
    title: 'Because Tomorrow Is Almost Here',
    cta: 'view success story',
    expanded: {
      title: 'Visionary. Invested. Future-ready.',
      subtitle: 'With CCL’s help, Mars’ new crop of leaders will be ready to take on anything.',
      contentTitle: '',
      link: 'https://www.ccl.org/articles/client-successes/reversing-talent-drought-generating-millions/',
      content:
        '<p>Mars has big plans for the future. With a new vision, smart growth, and a whole new management structure, the 100-year-old organization found itself in a unique position: there was a shortage of internal talent ready to grow into the senior leadership roles needed to fulfill its vision for the future.</p><p>To solve this global challenge, CCL partnered on an Accelerated Leadership Program (ALP) designed to prepare Mars’ most promising people to step into critical leadership roles in the coming years. Building on the company’s internal programs, the ALP has enabled Mars to confidently promote more well-qualified people into senior leadership roles &mdash; and into the future.</p>'
    }
  }
];

const stories2 = [
  {
    image: Story3,
    title: 'Making Leadership Personal',
    cta: 'view success story',
    expanded: {
      title: 'Global. Proactive. Galvanizing Influence.',
      subtitle: 'With CCL, Barclaycard’s leaders are ready to influence teams for global growth.',
      contentTitle: '',
      link: 'https://www.ccl.org/articles/leading-effectively-articles/barclaycard-links-business-strategy-leader-development/',
      content:
        '<p>An ambitious expansion and globalization plan left Barclaycard with an unexpected challenge: today’s leaders, while high-performing, were not ready for the challenges that lay ahead. The company’s Executive Development team realized that, for the plan to succeed, leadership needed to improve their skills around influencing without authority, coaching, global awareness, and translating strategy into action.</p><p>To accomplish this, Barclaycard partnered with CCL and Ashridge Business School to create the Executing Strategy Program. Two separate 3-day sessions focused on individual coaching and action-learning projects. These elements helped participants integrate lessons learned into their personal development, sparking them to create plans for converting strategy to operations and build stronger relationships within their departments and across the company. Now, key leaders are better equipped to face complex challenges and drive innovation &mdash; and that impact is cascading throughout the organization.</p>'
    }
  },
  {
    image: Story4,
    title: 'Driving Culture Change Through Empathy',
    cta: 'view success story',
    expanded: {
      title: 'New Values. New Culture. New Way Forward.',
      subtitle: 'Yara partnered with CCL to shift its established operations to a more collaborative culture.',
      contentTitle: '',
      link: 'https://www.ccl.org/articles/client-successes/ccl-partners-with-yara-in-culture-shift/',
      content:
        '<p>With roots going back over 100 years, Oslo-based Yara is the world’s largest supplier of plant nutrients. Historically, the organization’s culture was heavily focused on rules-based compliance. However, as the organization’s compliance culture matured, it became clear that leadership needed to create a more intentional shift toward collaboration.</p><p>Making the change from a rules-based culture to a values-driven culture is not an easy feat. Any change had to be embraced by nearly 15,000 staff over 60 countries. CCL worked with key Yara stakeholders on a 2-day retreat, where leaders clarified the new vision. The team also leveraged CCL’s Change Leadership Framework, which encourages leaders to think about leadership as a social process through which all members of an organization can participate. With this new lens, the group was able to not only anticipate the concerns of others, but also roll out the new vision and culture in a way that made them feel heard and valued.</p>'
    }
  }
]

let cards = [
  {
    header: 'Explore the latest thinking from CCL',
    subheader: '',
    cards: [
      {
        title: 'Better Culture Starts with Better Conversations',
        link: 'https://www.ccl.org/articles/white-papers/better-culture-starts-with-better-conversations/',
        label: 'Download White Paper'
      },
      {
        title: '5 Characteristics of a Successful Leadership Development Strategy',
        link: 'https://www.ccl.org/articles/leading-effectively-articles/5-characteristics-of-a-successful-leadership-development-strategy/',
        label: 'Read Article'
      },
      {
        title: 'Disruptive Trends Report: Talent Reimagined 2020',
        link: 'https://www.ccl.org/articles/research-reports/talent-reimagined-disruptive-trends-2020/',
        label: 'Download Report'
      },
      {
        title: 'Scaling Leadership Development for Maximum Impact',
        link: 'https://www.ccl.org/articles/white-papers/scaling-leadership-development/ ',
        label: 'Download White Paper'
      },
      {
        title: 'Case Study: Exploring the Benefits of Virtual Leadership Development',
        link: 'https://www.ccl.org/articles/client-successes/benefit-virtual-leader-development/',
        label: 'View Case Study'
      },
      {
        title: 'Consider These 4 Styles of Coaching',
        link: 'https://www.ccl.org/articles/leading-effectively-articles/4-types-of-coaching/',
        label: 'Read Article'
      }
    ]
  }
];

const toggleStory = s => {
  const stories = Array.prototype.slice.call(document.querySelectorAll(".open"), 0);
  for (let index = 0; index < stories.length; index++) {
    const story = stories[index];
    story.classList.remove('open');
  }
  let openedStory = document.getElementById('story' + s);
  openedStory.classList.add('open');
  let openSection;
  if (window.innerWidth > 900) {
    openSection = document.getElementById('card' + s);
    openSection.classList.add('open');
  }
  openedStory.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

const closeStory = () => {
  const stories = Array.prototype.slice.call(document.querySelectorAll('.open'), 0);
  for (let i = 0; i < stories.length; i++) {
    const story = stories[i];
    story.classList.remove('open');
  }
};

const storyContent = (content, x) => {
  let storyLink = content.link ? true : false;
  let openHeadline = content.title.split('.');
  return (
    <div className="explore-open" id={'story' + x}>
      <div className="close-story" onClick={closeStory}>
        <span>X</span>
      </div>
      <h2>{openHeadline[0] + '.'}</h2>
      <h2>{openHeadline[1] + '.'}</h2>
      <h2>{openHeadline[2] + '.'}</h2>
      <h3>{content.subtitle}</h3>
      <span dangerouslySetInnerHTML={{ __html: content.content }}></span>
      {storyLink && (
        <a href={content.link} target="_blank">
          <CCLButton style="primary" label="Read the full case study" arrow="true" />
        </a>
      )}
    </div>
  )
}

const Organizations = () => (
  <Layout>
    <SEO
      title="Organizations | Organizational Transformation"
      description="Strong leadership is necessary for positive organizational transformation. Start your organization’s leadership journey with us today."
      meta={{
        imgAlt: 'An organizational leadership group gathers to collaborate on the leadership and culture strategy for their company. '
      }}
    />
    <CCLLeadspace image={LeadImage} copy="Leaders made.  Disruptions embraced.  Organizations transformed." />
    <OrgStats />
    <div className="desktop-stories">
      <div className="stories">
        {stories.map((link, i) => (
          <span
            key={link.title}
            onClick={() => {
              toggleStory(i);
            }}
          >
            <CCLExplore className="story" label={link.title} cardId={'card' + i} cta={link.cta} image={link.image} expandedContent={true}></CCLExplore>
          </span>
        ))}
      </div>

      <div className="openStories">
        {stories.map((story, x) => (
          <React.Fragment key={story.title}>{storyContent(story.expanded, x)}</React.Fragment>
        ))}
      </div>

      <div className="stories">
        {stories2.map((link, i) => (
          <span
            key={link.title}
            onClick={() => {
              toggleStory(i + 2);
            }}
          >
            <CCLExplore className="story" label={link.title} cardId={'card' + Number(i + 2)} cta={link.cta} image={link.image} expandedContent={true}></CCLExplore>
          </span>
        ))}
      </div>

      <div className="openStories">
        {stories2.map((story, x) => (
          <React.Fragment key={story.title}>{storyContent(story.expanded, x + 2)}</React.Fragment>
        ))}
      </div>
    </div>

    <div className="mobile-stories">
      <div className="stories">
        <span
          onClick={() => {
            toggleStory(5);
          }}
        >
          <CCLExplore className="story" label={stories[0].title} cardId={'card' + 5} cta={stories[0].cta} image={stories[0].image} expandedContent={true}></CCLExplore>
        </span>
        {storyContent(stories[0].expanded, 5)}

        <span
          onClick={() => {
            toggleStory(6);
          }}
        >
          <CCLExplore className="story" label={stories[1].title} cardId={'card' + 6} cta={stories[1].cta} image={stories[1].image} expandedContent={true}></CCLExplore>
        </span>
        {storyContent(stories[1].expanded, 6)}

        <span
          onClick={() => {
            toggleStory(7);
          }}
        >
          <CCLExplore className="story" label={stories2[0].title} cardId={'card' + 7} cta={stories2[0].cta} image={stories2[0].image} expandedContent={true}></CCLExplore>
        </span>
        {storyContent(stories2[0].expanded, 7)}

        <span
          onClick={() => {
            toggleStory(8);
          }}
        >
          <CCLExplore className="story" label={stories2[1].title} cardId={'card' + 8} cta={stories2[1].cta} image={stories2[1].image} expandedContent={true}></CCLExplore>
        </span>
        {storyContent(stories2[1].expanded, 8)}
      </div>
    </div>
    <CardSlider {...cards} />
    <JourneyForm />
  </Layout>
);

export default Organizations;
